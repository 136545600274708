@import "../../../assets/styles/mixins";

.login-input {
  background-color: transparent;
  border: none;
  color: var(--dark);
  padding-left: 3px;
  font-size: $font-size-base;
  height: 45px;

  &:focus,
  &:hover,
  &:active {
    border: none;
    background-color: transparent;
    color: var(--dark);
    box-shadow: 0 0 0 transparent;
  }
}

.loginHeading {
  color: var(--dark);
  font-size: $font-size-base + 0.5;
  font-weight: 600;
}

.login-back-btn {
  color: var(--blue);
  padding: 0;
  display: inline-flex;
  align-items: center;

  span {
    height: 18px;
    display: inline-flex;
    align-items: center;
  }

  svg {
    height: 18px !important;
  }

  &:hover {
    color: var(--dark);
    text-decoration: none;
  }
}

.loginBg {
}

.toggle-other-login-methods-btn {
  border: 1px solid var(--gray);
  /* background-color: var(--secondary); */
  background-color: transparent;
  padding: 10px 30px;
  text-align: left;
  color: var(--gray-2);
  font-size: $font-size-base + 0.1;
  width: 100%;
  max-width: 340px;

  .toggle-login-method-icon {
     display: inline-block;
     margin-right: 20px;
  }

  &:hover {
    color: var(--dark);
    background-color: var(--light);

    &.facebook {
       background-color: var(--facebook);
       color: white;

       svg {
          fill: white;
       }
    }

    &.google {
       background-color: var(--google);
       color: white;

       svg {
          fill: white;
       }
    }

    &.email {
       background-color: var(--dark);
       color: white;

       svg {
          fill: white;
       }
    }
  }


}

.login-form {
  /* width: 390px; */
}

.phone-country-block {
  border-bottom: 1px solid var(--gray);

  .phone-country-code {
    border: none;
    height: 40px;
    background-color: transparent;
    background-repeat: no-repeat;
    color: var(--gray-2);
    font-size: $font-size-base;
    padding: 0 8px;
    width: 60px !important;
    padding-left: 0;
    float: left;
    letter-spacing: 2px;
  }

  .phone-input {
    background-color: transparent;
    border: none;
    color: var(--dark);
    font-size: $font-size-base + 0.3;
    float: left;
    width: calc(100% - 60px);
    letter-spacing: 2px;
    height: 45px;

    &:hover,
    &:focus,
    &:focus-within,
    &:active {
      border: none;
      background-color: transparent;
      box-shadow: 0 0 0 transparent;
    }

    &:-internal-autofill-selected {
      background-color: transparent !important;
    }

    &::-webkit-input-placeholder {
      /* Edge */
      font-size: $font-size-base + 0.1;
      letter-spacing: 0px;
      color: #aaa;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      font-size: $font-size-base + 0.1;
      letter-spacing: 0px;
      color: #aaa;
    }

    &::placeholder {
      font-size: $font-size-base + 0.1;
      letter-spacing: 0px;
      color: #aaa;
    }
  }
}

.form-error {
  color: #f85959;
}

.submit-phone-btn {
  font-size: $font-size-base + 0.1;
}

.otp-title {
  color: var(--dark);
  font-size: $font-size-base + 0.5;
}

.otp-block {
  max-width: 250px;
  border-bottom: 1px solid var(--gray);
  clear: both;
  text-align: center;
}

.otp-input {
  font-size: $font-size-base + 1.5;
  letter-spacing: 15px;
  text-align: center;

  &:focus {
    background-color: transparent;
    color: var(--dark);
  }

  &::-webkit-input-placeholder {
    /* Edge */
    font-size: $font-size-base + 1.5;
    letter-spacing: 10px;
    text-align: left;
    color: #ccc;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-size: $font-size-base + 1.5;
    letter-spacing: 10px;
    text-align: left;
    color: #ccc;
  }

  &::placeholder {
    font-size: $font-size-base + 1.5;
    letter-spacing: 10px;
    text-align: left;
    color: #ccc;
  }
}

.resend-btn {
  background-color: transparent;
  color: var(--dark);
  line-height: 1.2;
  margin-top: 5px;

  &:disabled,
  &.disabled {
    background-color: var(--secondary);
    color: $gray;
  }
}

.social-logins-list {
  li {
    float: left;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
}

.social-login {
  width: 320px;
  background-color: var(--dark);
  height: 44px;
  color: var(--dark);
  line-height: 1.1;
  font-size: $font-size-base + 0.1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 30px;
  padding-right: 30px;

  .social-login-icon {
    margin-right: 15px;
  }

  &:hover {
    background-color: #000;
    color: var(--dark);
  }

  /* &.facebook {
      background-color: #000;
   }

   &.google {
      background-color: var(--gray);
      color: #333;
   } */
}

.thanks-message {
  padding: 40px 25px;

  .thanks-title {
    font-size: $font-size-base + 0.6;
    font-weight: 700;
    color: var(--dark);
    line-height: 1.1;
  }

  .thanks-info {
    color: var(--dark);
    font-size: $font-size-base + 0.2;
  }
}

.login-outer-block {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  z-index: 20;
  color: var(--dark);

  .login-outer-overlay {
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  }

  .login-form-outer-block {
    position: relative;
    width: 100%;

    z-index: 4;
  }

  .login-block {
    width: 100%;
  }

  .login-main {
    background-color: var(--white);
    float: left;
    width: 100%;
    min-height: 100vh;

  }

  .login-form-header {
    border-bottom: 1px solid var(--gray);
    padding: 15px 60px 15px 20px;
    position: relative;
  }

  .login-close-btn {
    position: absolute;
    right: 10px;
    top: 13px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;

    svg {
      width: 32px !important;
      height: 32px !important;
    }
  }

  .login-title {
    text-align: left;
    font-size: $font-size-base + 0.3;
    color: var(--gray-2);
  }

  .login-form-content {
    padding: 20px 30px;
    color: var(--gray-2);
    font-size: $font-size-base - 0.1;
  }



  @include media-breakpoint-up(md) {
    padding-top: 30px;
    padding-bottom: 30px;

    .login-block {
      min-height: inherit;
      max-width: 460px;
      margin: 0px auto;
    }

    .login-form-content {
      padding: 20px 40px;
    }

    .login-main {
      min-height: inherit;
      border-radius: 5px;
    }
  }
}

input:-internal-autofill-selected {
  background: none !important;
}

.form-list {
  li {
    float: left;
    width: 100%;
  }
}

.otpError {
  margin-top: 5px;
}