@import "../../../assets/styles/mixins";

.tv-channel-heading {
   text-align: center;

   @include media-breakpoint-up(md) {
      text-align: left;
   }
}

.tvSubscribeBtn {
   font-size: $font-size-base + 0.15;
   display: inline-flex;
   align-items: center;
   justify-content: center;
   padding-left: 30px;
   padding-right: 30px;
   font-weight: 600;

   .try-rupee {
      width: 16px;
      height: 16px;
      display: inline-block;
      margin-left: 3px;

      svg {
         width: 16px !important;
         height: 16px !important;
         vertical-align: top;
      }
   }

   .try-rupee-text {
      margin-right: 4px;
   }
}

.channel-category-title {
   font-weight: 500;
   font-size: $font-size-base + 0.3;
   color: var(--dark);
   border-bottom: 1px solid #eee;
   padding-bottom: 5px;
   margin-bottom: 20px;
}

.subscribe-price {
   width: 100%;
   display: inline-flex;
   flex-direction: column;

   .subscribe-info {
      display: block;
      text-align: center;
      color: var(--gray-2);
      font-size: $font-size-base - 0.17;
      margin-top: 5px;
      font-weight: 500;
   }

   @include media-breakpoint-up(sm) {
      max-width: 200px;
      text-align: center;
      float: right;
   }
   @include media-breakpoint-up(md) {
      text-align: right;
   }
}

.category-channels-list {
   display: grid;
   grid-template-columns: 50% 50%;
   column-gap: 15px;
   row-gap: 15px;

   @include media-breakpoint-up(sm) {
      grid-template-columns: 33.33% 33.33% 33.33%;
   }

   @include media-breakpoint-up(md) {
      grid-template-columns: 25% 25% 25% 25%;
   }

   @include media-breakpoint-up(lg) {
      grid-template-columns: 20% 20% 20% 20% 20%;
   }

   @include media-breakpoint-up(xl) {
      grid-template-columns: 16.66% 16.66% 16.66% 16.66% 16.66% 16.66%;
   }
}

.channel-item {
   float: left;
   width: 100%;
   position: relative;

   .channel-item-thumbnail-block {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      position: relative;
      z-index: 2;
      height: 120px;
   }

   .channel-item-thumbnail {
      max-width: 100%;
      max-height: 100%;
      position: relative;
      transition: all 0.3s ease-in;
   }

   .channel-play-btn {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
      z-index: 4;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease;

      .playIcon {
         display: none;
      }
   }

   .channel-add-wishlist-btn {
      position: absolute;
      left: 10px;
      top: 10px;
      width: 20px;
      height: 20px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 6;
      display: none;

   }

   @include media-breakpoint-up(lg) {

      .channel-play-btn {
         .playIcon {
            display: block;
            transform: scale(0.8);
            transition: all 0.4s ease-in-out;
            opacity: 0;
         }
      }

      .channel-add-wishlist-btn {
         display: flex;
         transform: translateX(-120%);
         transition: all 0.2s ease-in;
      }

      &:hover {
         .channel-item-thumbnail {
            transform: scale(1.1);
         }

         .channel-play-btn {
            background-color: rgba(0,0,0,0.6);

            .playIcon {
               opacity: 1;
               transform: scale(1);
            }
         }

         .channel-add-wishlist-btn {
            transform: translateX(0);
         }
      }

   }

}