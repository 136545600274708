@import "../../../assets/styles/mixins";

.subscribe-title {
  font-size: $font-size-base + 0.7;
  color: white;
  font-weight: 700;
  text-align: center;
}

.subscribe-content {
  background-color: var(--dark);
  border-radius: 5px;
}

.subscribe-list {
  li {
    float: left;
    width: 100%;
    margin-bottom: 15px;
  }

  .subscribe-col-title {
    font-size: $font-size-base - 0.28;
    background-color: var(--yellow);
    color: #333;
    line-height: 1;
    padding: 3px 7px;
    border-radius: 3px;

    &.purple {
       background-color: var(--purple);
    }

    &.orange {
       background-color: var(--orange);
    }

    &.pink {
       background-color: var(--pink);
    }
  }
}

.subscribe-plans-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @include media-breakpoint-up(lg) {
  }
}

.each-subscribe-price {
  display: flex;
  width: 50%;
  position: relative;
  padding: 6px;
  cursor: pointer;

  .each-subscribe-price-block {
    position: relative;
    border: 1px solid var(--dark);
    border-radius: 10px;
    background-color: var(--secondary);
    padding: 12px 15px;
  }

  .name-title {
    background-color: var(--yellow);
    line-height: 1;
    font-size: $font-size-base - 0.29;
    color: #333;
    padding: 3px 5px;
    border-radius: 5px;
    text-transform: uppercase;
  }

  .subscribe-price {
    display: flex;
    margin-top: 5px;

    .rupee {
      width: 17px;
      display: inline-flex;
      align-self: flex-start;

      svg {
        width: 100% !important;
      }
    }
  }

  .subscribe-price-number {
    font-size: $font-size-base + 1.5;
    line-height: 0.8;
    color: white;
    font-weight: 700;
    display: inline-flex;
    align-self: flex-start;
    padding-right: 5px;
  }

  .subscirbe-days {
    display: inline-flex;
    align-self: flex-end;
    font-size: $font-size-base + 0.1;
    line-height: 1;
  }

  .subscribe-price-duration {
    margin-top: 5px;
  }

  .each-subscribe-price-input {
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    overflow: hidden;
    opacity: 0;
    padding: 0;

    &:checked + .each-subscribe-price-block {
       background-color: var(--dark);
       box-shadow: 1px 1px 5px rgba(0,0,0,0.7);
      &::before {
        content: "";
        width: 20px;
        height: 20px;
        position: absolute;
        right: 10px;
        top: 10px;
        border-radius: 100px;
        background-color: white;
        z-index: 2;
      }

      &::after {
        content: "";
        width: 12px;
        height: 8px;
        border-top: 3px solid var(--secondary);
        border-right: 3px solid var(--secondary);
        border-radius: 1px;
        position: absolute;
        right: 14px;
        top: 14px;
        z-index: 4;
        transform: rotate(135deg);
      }
    }
  }

  @include media-breakpoint-up(md) {
    width: 50%;
  }

  @include media-breakpoint-up(lg) {
    width: 25%;
  }
}


.continue-subscribe-btn {
   /* background-color: $blue; */
   font-size: $font-size-base + 0.3;
   color: white;

   &:hover {
      color: white;
      background-color: darken($primary, 5%);
   }
}