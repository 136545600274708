@import "../../../assets/styles/mixins";

.no-data-found-block {
   float: left;
   width: 100%;
   padding-top: 50px;
   min-height: 250px;
   /* background-color: var(--secondary); */
   /* box-shadow: 0px 0px 85px 0px rgba(0,0,0,0.75) inset;
-webkit-box-shadow: 0px 0px 85px 0px rgba(0,0,0,0.75) inset;
-moz-box-shadow: 0px 0px 85px 0px rgba(0,0,0,0.75) inset; */

   .no-data-found-text {
      font-size: $font-size-base + 1;
      color: white;
      line-height: 1.3;
      text-shadow: 0 0 3px rgba(0,0,0,0.1);
      opacity: 0.9;

   }

   @include media-breakpoint-up(md) {
      padding-top: 70px;

      .no-data-found-text {
         font-size: $font-size-base + 1;
      }
   }
}