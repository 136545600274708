@import "../../../assets/styles/mixins";

.footer {
  padding: 40px 0;
  color: #777;
  font-size: $font-size-base;
  font-family: $font-family-base-2;
}

.footer-links-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;


  .each-footerlink {
    margin-bottom: 10px;
    margin-right: 15px;
    color: var(--gray-2);


    &:hover {
      color: var(--dark);
    }
  }
}

.social-links-list {
  display: flex;
  align-items: center;
  margin-top: 5px;

  .each-social-link {
    background-color: transparent;
    width: 40px;
    height: 40px;
    border-radius: var(--border-radius);
    border: 1px solid #ccc;
    margin-right: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    img {
      width: 24px;
      opacity: 0.7;
    }

    &:hover {
      background-color: #ddd;
      img {
        opacity: 1;
      }
    }
  }
}

.apps-list-links {
  display: flex;
  align-items: center;
  margin-top: 10px;

  .storeLink {
    width: 150px;
    margin-bottom: 15px;
    margin-right: 10px;

    img {
      width: 100%;
    }
  }
}


.copyrights {
  font-size: $font-size-base - 0.2;
}